import { useAuth0 } from "@auth0/auth0-react";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { getBagCodes, getTenant } from "../api/orderportal_apimanager";
import { Tenant } from "../api/types/Tenant";
import BackButton from "../modules/backbutton";
import Navigation from "../modules/navigation";
import NotSignedIn from "../modules/notsignedin";
import toastManager from "../modules/toastmanager";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { CreateBagCodeSheet } from "../modules/pdf/createBagCodeSheet";
import Barcode from "react-barcode";

import ReactDOMServer from "react-dom/server";

export default function AdminPanel() {
	const { isAuthenticated, user } = useAuth0();
	const navigate = useNavigate();
	const token = JSON.parse(localStorage.getItem("accessToken") ?? "{}");
	useEffect(() => {
		if (!token.length) navigate("/home");
	}, [navigate, token.length]);

	const [tenant, setTenant] = useState<Tenant | undefined>();
	const isAuthorized: boolean = true;

	const tenantID = localStorage.getItem("tenant");

	const [barcodesFromAPI, setBarcodesFromAPI] = useState<string[]>([]);

	useEffect(
		function RetrieveTenant() {
			if (token && tenantID)
				getTenant(token, tenantID).then((response) => {
					setTenant(response as Tenant);
				});
		},
		[tenantID, token]
	);

	useEffect(
		function getBarcodesFromApi() {
			if (tenantID) getBagCodes(token, tenantID, "0052").then((response) => setBarcodesFromAPI(response));
		},
		[tenantID, token]
	);

	return (
		<>
			<Navigation />
			{isAuthenticated && isAuthorized && (
				<>
					<Container className="paddingTopBottom">
						<BackButton
							route="/home"
							title="homepage"
						/>

						<h1>Administrator panel </h1>
						<Row>
							<Col></Col>
							<Col>All actions here are portal wide and will be logged.</Col>
							<Col></Col>
						</Row>
						<br />
						<Row className="gy-4 admin">
							<Col sm={4}>
								<Card
									className="admin-card"
									onClick={() => navigate("brandconfig")}
								>
									<Card.Header>
										<p className="admin">
											<Icon.Palette2 />
											<br /> Brand configuration{" "}
										</p>
									</Card.Header>
								</Card>
							</Col>
							<Col sm={4}>
								<Card
									className="admin-card"
									border="none"
									onClick={() => navigate("productconfig")}
								>
									<Card.Header>
										<p>
											<Icon.Tools />
											<br />
											Product configuration
										</p>
									</Card.Header>
								</Card>
							</Col>

							<Col
								className="admin-card"
								sm={4}
							>
								<Card
									border="none"
									// onClick={() => navigate("/logs")}
								>
									<Card.Header>
										<p>
											{" "}
											<Icon.ListColumnsReverse />
											<br /> Logs
										</p>
									</Card.Header>
								</Card>
							</Col>
							<PDFDownloadLink
								document={<CreateBagCodeSheet barcodes={barcodesFromAPI} />}
								fileName={`BagCodeSheet.pdf`}
							>
								{/* @ts-ignore */}
								{({ blob, url, loading, error }) => (loading ? "Loading.." : <Button>DOWNLOAD BAGCODESHEET<Icon.CloudDownloadFill /></Button>)}
							</PDFDownloadLink>


							{/* <PDFViewer height={1400}>
								<CreateBagCodeSheet barcodes={barcodesFromAPI}/>
							</PDFViewer> */}
							{/* <Col sm={4}>
								<Card
									border="none"
									onClick={() => toast.error(`Removed a user of ${tenant?.name}`)}
								>
									<Card.Header>
										<p>
											<Icon.FileEarmarkBinaryFill />
											<br /> TEST configuration
										</p>
									</Card.Header>
								</Card>
							</Col>

							<Col sm={4}>
								<Card
									border="none"
									onClick={() => toast.info("Something happened")}
								>
									<Card.Header>
										<p>
											<Icon.Braces />
											<br /> TEST configuration
										</p>
									</Card.Header>
								</Card>
							</Col>
							<Col sm={4}>
								<Card
									border="none"
									onClick={() => toast.info("Something happened")}
								>
									<Card.Header>
										<p>
											<Icon.BracesAsterisk />
											<br /> TEST configuration
										</p>
									</Card.Header>
								</Card>
							</Col>
							<Col sm={4}>
								<Card
									border="none"
									onClick={() => toast.info("Something happened")}
								>
									<Card.Header>
										<p>
											<Icon.WrenchAdjustableCircleFill />
											<br />TEST configuration
										</p>
									</Card.Header>
								</Card>
							</Col> */}
						</Row>
					</Container>
				</>
			)}
			{!isAuthenticated && <NotSignedIn />}
		</>
	);
}

import { Document, Image, Page, StyleSheet, View } from "@react-pdf/renderer";
import JsBarcode from "jsbarcode";
import React from "react";

const BarCodeWrapper = (props: { code: string }) => {
	let canvas = document.createElement("canvas");
	JsBarcode(canvas, props.code, {
		height: 30,
	});
	const barcode = canvas.toDataURL();
	return <Image src={barcode}></Image>;
};
interface Props {
	barcodes: string[];
}

export const CreateBagCodeSheet = (props: Props) => {
	const style = StyleSheet.create({
		row: {
			flexDirection: "row",
		},
		col: {
			flexDirection: "column",
			padding: "5px",
		},
		page: {
			justifyContent: "space-evenly",
			paddingLeft: "15px",
			paddingRight: "15px",
			height: "100vh",
		},
	});

	function GeneratePage() {
		let rows = [];
		for (let i = 0; i < props.barcodes.length; i += 4) {
			rows.push(
				<View style={style.row} key={props.barcodes[i]}>
					<View style={style.col}>
						<BarCodeWrapper code={props.barcodes[i]} />
					</View>
					<View style={style.col}>
						<BarCodeWrapper code={props.barcodes[i + 1]} />
					</View>
					<View style={style.col}>
						<BarCodeWrapper code={props.barcodes[i + 2]} />
					</View>
					<View style={style.col}>
						<BarCodeWrapper code={props.barcodes[i + 3]} />
					</View>
				</View>
			);
		}

		return <>{rows}</>;
	}

	return (
		<>
			<Document>
				<Page style={style.page}>
					<GeneratePage />
				</Page>
			</Document>
		</>
	);
};

import { useAuth0 } from "@auth0/auth0-react";
import * as React from "react";
import { useEffect, useState } from "react";
import { Button, Col, Container, FloatingLabel, Form, ListGroup, Modal, Offcanvas, Row } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import BackButton from "../modules/backbutton";
import Navigation from "../modules/navigation";
import NotSignedIn from "../modules/notsignedin";
import { ListItem } from "../api/types/ListItem";
import Select from "react-select";
import toastManager from "../modules/toastmanager";

export default function RMA() {
	const { isAuthenticated } = useAuth0();
	const navigate = useNavigate();
	const token = JSON.parse(localStorage.getItem("accessToken") ?? "{}");
	useEffect(() => {
		if (!token.length) navigate("/home");
	}, [navigate, token.length]);

	const queryParameters = new URLSearchParams(window.location.search);
	const serialnumber = queryParameters.get("serialnumber");

	const [checks, setChecks] = useState<Action[]>([]);
	const [showChargeSolution, setShowChargeSolution] = useState<boolean>(false);
	const handleCloseChargeSolution = () => setShowChargeSolution(false);
	const handleShowChargeSolution = () => setShowChargeSolution(true);
	const [showEarbudDetectionSolution, setShowEarbudDetectionSolution] = useState<boolean>(false);
	const handleCloseEarbudDetectionSolution = () => {
		setShowEarbudDetectionSolution(false);
		setShowChargeSolution(true);
	};
	const handleShowEarbudDetectionSolution = () => setShowEarbudDetectionSolution(true);

	const [selectedProblem, setSelectedProblem] = useState<"no_hearing_protection_modes" | "no_charging" | "no_sound_or_music" | "no_call" | "no_connect" | "not_satisfied" | "other">();
	const problems: ListItem[] = [
		{ label: "Hearing protection modes do not work", value: "no_hearing_protection_modes" },
		{ label: "Can't charge", value: "no_charging" },
		{ label: "No sound/cannot play music", value: "no_sound_or_music" },
		{ label: "Can't call", value: "no_call" },
		{ label: "Can't connect", value: "no_connect" },
		{ label: "Unsatisfied with product/quality", value: "not_satisfied" },
		{ label: "Other...", value: "other" },
	];

	type Action = {
		id: string;
		primary: string;
		secondary: string;
		RMA: "Dopple" | "customer" | "noSolve" | "no";
		solve?: string;
		requirePicture?: "required" | "preferred" | "no";
	};

	const visualInspection: Action[] = [
		{ id: "visual_inspection", primary: "Visual inspection", secondary: "title", RMA: "no" },
		{
			id: "visual_inspection_1",
			primary: "Left earshell",
			secondary: "No severe visual damage or misuse",
			RMA: "no",
			requirePicture: "required",
		},
		{
			id: "visual_inspection_2",
			primary: "Right earshell",
			secondary: "No severe visual damage/misuse",
			RMA: "no",
			requirePicture: "required",
		},
		{
			id: "visual_inspection_3",
			primary: "Left wax guard",
			secondary: "No severe visual damage/misuse",
			RMA: "no",
			requirePicture: "required",
		},
		{
			id: "visual_inspection_4",
			primary: "Right wax guard",
			secondary: "No severe visual damage/misuse",
			RMA: "no",
			requirePicture: "required",
		},
		{
			id: "visual_inspection_5",
			primary: "Left e-module",
			secondary: "No severe visual damage/misuse",
			RMA: "no",
			requirePicture: "required",
		},
		{
			id: "visual_inspection_6",
			primary: "Right e-module",
			secondary: "No severe visual damage/misuse",
			RMA: "no",
			requirePicture: "required",
		},
		{
			id: "visual_inspection_7",
			primary: "Cradle",
			secondary: "No severe visual damage/misuse",
			RMA: "no",
			requirePicture: "required",
		},
	];
	const cleaning: Action[] = [
		{ id: "cleaning", primary: "Cleaning", secondary: "title", RMA: "no" },
		{
			id: "cleaning_1",
			primary: "Remove both wax guards",
			secondary: "Remove the left and right wax guard before continuing",
			RMA: "no",
		},
		{
			id: "cleaning_2",
			primary: "Clean all earbud charge contacts",
			secondary: "Clean the charge contacts of the left and right earbud",
			RMA: "no",
		},
		{
			id: "cleaning_3",
			primary: "Clean the charge contacts of the cradle",
			secondary: "Clean the charge contacts of the cradle",
			RMA: "no",
		},
	];
	const general: Action[] = [
		{ id: "general", primary: "General Checks", secondary: "title", RMA: "no" },
		{
			id: "general_1",
			primary: "Open the cradle door",
			secondary: "All three LEDs turn on?",
			RMA: "no",
			solve: "charge",
		},
		{
			id: "general_2",
			primary: "Wait for 7 seconds",
			secondary: "All the LEDs are off?",
			RMA: "no",
		},
		{
			id: "general_3",
			primary: "Press the cradle button",
			secondary: "The middle LED turns on?",
			RMA: "Dopple",
		},
		{
			id: "general_4",
			primary: "Put the left earbud back in the cradle",
			secondary: "The left LED turns on for 1 second?",
			RMA: "no",
			solve: "earbud_detection",
		},
		{
			id: "general_5",
			primary: "Put the right earbud back in the cradle",
			secondary: "The right LED turns on for 1 second?",
			RMA: "no",
			solve: "earbud_detection",
		},
	];
	const pairing: Action[] = [
		{ id: "pairing", primary: "Pairing Checks", secondary: "title", RMA: "no" },
		{
			id: "pairing_1",
			primary: "Long press the button on the cradle",
			secondary: "The middle LED turns on (or all LEDs)?",
			RMA: "no",
		},
		{
			id: "pairing_2",
			primary: "Wait until all LEDs are blinking",
			secondary: "All LEDs are blinking?",
			RMA: "no",
			solve: "hardware_reset",
		},
		{
			id: "pairing_3",
			primary: "Check if device is visible on mobile phone",
			secondary: "Device is visible?",
			RMA: "no",
		},
		{
			id: "pairing_4",
			primary: "Pair with device on mobile phone",
			secondary: "LEDs turn off?",
			RMA: "no",
		},

		{
			id: "pairing_5",
			primary: "Take earbuds out of cradle an put them in the ear",
			secondary: "earbuds connect to mobile phone",
			RMA: "no",
		},
	];
	const music: Action[] = [
		{ id: "music", primary: "Music Checks", secondary: "title", RMA: "no" },
		{
			id: "music_1",
			primary: "play music on mobile phone",
			secondary: "Left and Right undistorted audio and normal level?",
			RMA: "no",
			solve: "audio_check",
		},
		//! RMA excel = press and hold button -> volume goes up/down || this can be changed in settings so not a good check?!
		{
			id: "music_2",
			primary: "Press right button",
			secondary: "audible click?",
			RMA: "Dopple",
		},
		{
			id: "music_3",
			primary: "Press left button",
			secondary: "audible click?",
			RMA: "Dopple",
		},
		{
			id: "music_4",
			primary: "Take both earbuds out of the ear",
			secondary: "Music stops playing?",
			RMA: "no",
			solve: "earbud_removal_setting",
		},
	];

	function handleSelectIssue(issue: string) {
		// These checks should always be done
		const defaultChecks = [...visualInspection, ...cleaning, ...general];
		switch (issue) {
			case "no_hearing_protection_modes":
				setChecks([...defaultChecks, ...pairing]);
				break;
			case "no_charging":
				setChecks([...defaultChecks]);
				break;
			case "no_sound_or_music":
				setChecks([...defaultChecks]);
				break;
			case "no_call":
				setChecks([...defaultChecks]);
				break;
			case "no_connect":
				setChecks([...defaultChecks]);
				break;
			case "not_satisfied":
				setChecks([...defaultChecks]);
				break;
			case "other":
				setChecks([...defaultChecks]);
				break;
			default:
				toastManager("error", "Something went wrong", "Please contact the administrator. This issue should not exist");
				break;
		}
	}

	function PopulateChecks() {
		var temp: JSX.Element[] = [];
		temp.push(
			<>
				<Row>
					<Col sm={6}></Col>
					<Col sm={1}></Col>
					<Col sm={1}></Col>
					<Col>Upload picture</Col>
				</Row>
			</>
		);
		checks.forEach((check) => {
			temp.push(
				<ListGroup.Item
					key={check.id}
					variant={check.secondary === "title" ? "secondary" : "none"}
				>
					<Row>
						<Col className="fw-bold">{check.primary}</Col>
					</Row>
					<Row>
						{check.secondary !== "title" && (
							<>
								<Col sm={6}>{check.secondary}</Col>
								<Col sm={1}>
									<Form.Check
										name={`${check.id}`}
										type="radio"
										label="True"
									></Form.Check>
								</Col>
								<Col sm={1}>
									<Form.Check
										name={`${check.id}`}
										type="radio"
										label="False"
										onClick={() => handleNegativeOption(check.solve)}
									></Form.Check>
								</Col>
							</>
						)}
						{check.requirePicture === "required" && (
							<Col>
								<Form.Control
									type="file"
									accept="image/*"
									multiple
									required
								/>
							</Col>
						)}
					</Row>
				</ListGroup.Item>
			);
		});

		if (temp !== undefined) return <>{temp}</>;
		else return <></>;
	}

	function handleNegativeOption(option: string | undefined) {
		switch (option) {
			case "charge":
				handleShowChargeSolution();
				break;
			case "earbud_detection":
				handleShowEarbudDetectionSolution();
				break;
			default:
				break;
		}
	}

	function handleReturn(){
		console.error("Return item to us")
	}

	return (
		<>
			<Navigation />
			{isAuthenticated && (
				<>
					<Container className="paddingTopBottom">
						<BackButton
							route="/home"
							title="homepage"
						/>
						<h1>RMA Questionnaire</h1>
						<Row>
							<Col sm={2}>
								<b>Serialnumber of cradle:</b>
							</Col>
							<Col sm={5}>
								<Form.Control
									type="input"
									defaultValue={serialnumber ?? ""}
									placeholder="Report RMA via profile page of user to pre-fill this field"
								/>
							</Col>
						</Row>
						<br />
						<Row>
							<Col sm={2}>
								<b>Issue: </b>
							</Col>
							<Col sm={5}>
								<Select
									options={problems}
									placeholder="Please select an issue that best fits the problem"
									onChange={(selected) => handleSelectIssue(selected?.value ?? "")}
								/>
							</Col>
							{selectedProblem === "other" && (
								<Col>
									<Form.Control
										type="input"
										placeholder="Please fill in what the issue is"
									/>
								</Col>
							)}
						</Row>
						<br />
						{checks !== undefined && <ListGroup>{PopulateChecks()}</ListGroup>}
					</Container>

					{/* CHARGING SOLUTION */}
					<Modal
						show={showChargeSolution}
						onHide={handleCloseChargeSolution}
						placement="end"
						backdrop="static"
					>
						<Modal.Header closeButton>
							<Offcanvas.Title className="fw-bold">Charge</Offcanvas.Title>
						</Modal.Header>
						<Modal.Body>
							<Row className="small">Follow the steps below to try and solve the problem</Row>
							<hr />
							<Row className="fw-bold">
								<Icon.Icon1CircleFill /> <br /> Connect the USB-charger to the cradle
							</Row>
							<br />
							<Row>All 3 LEDs turn on and are flashing during charging?</Row>
							<br />
							<Row className="gap-2">
								<Button
									disabled
									variant="success"
								>
									Yes, continue to next question
								</Button>
								<Button>No</Button>
							</Row>
							<hr />
							<Row className="fw-bold">
								<Icon.Icon2CircleFill /> <br /> Charge for 30 minutes, open cradle door
							</Row>
							<br />
							<Row>All LEDs are green?</Row>
							<br />
							<Row className="gap-2">
								<Button
									onClick={handleCloseChargeSolution}
									variant="success"
								>
									Yes, quit charge fix
								</Button>
								<Button onClick={handleReturn}>No, return item</Button>
							</Row>
						</Modal.Body>
					</Modal>

					{/* EARBUD DETECTION SOLUTION */}
					<Modal
						show={showEarbudDetectionSolution}
						onHide={handleCloseEarbudDetectionSolution}
						placement="end"
						backdrop="static"
					>
						<Modal.Header closeButton>
							<Offcanvas.Title className="fw-bold">Earbud Detection</Offcanvas.Title>
						</Modal.Header>
						<Modal.Body>
							<Row className="small">Follow the steps below to try and solve the problem</Row>
							<hr />

							<Row className="fw-bold">
								<Icon.Icon1CircleFill /> <br />
								Make sure cradle and earbud contacts are clean,
							</Row>
							<Row className="fw-bold">
								{" "}
								<br />
								Make sure the eargels are assembled properly,
							</Row>
							<Row className="fw-bold">
								{" "}
								<br />
								take both earbuds out of the cradle and put the left one back in the cradle
							</Row>
							<br />
							<Row>Left LED turns on green for 1 second?</Row>
							<br />
							<Row className="gap-2">
								<Button
									disabled
									variant="success"
								>
									Yes, continue to next step
								</Button>
								<Button>No</Button>
							</Row>
							<hr />
							<Row className="fw-bold">
								<Icon.Icon2CircleFill /> <br />
								Put the right earbud back in the cradle
							</Row>
							<br />
							<Row>Right LED turns on green for 1 second?</Row>
							<br />
							<Row className="gap-2">
								<Button
									onClick={handleCloseEarbudDetectionSolution}
									variant="success"
								>
									Yes, quit earbud detection fix and go to charge
								</Button>
								<Button>No</Button>
							</Row>
						</Modal.Body>
					</Modal>
				</>
			)}
			{!isAuthenticated && <NotSignedIn />}
		</>
	);
}

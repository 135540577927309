import React, { PropsWithChildren } from "react";
import ReactDOM from "react-dom/client";
// import './index.css';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AppState, Auth0Provider, Auth0ProviderOptions, withAuthenticationRequired } from "@auth0/auth0-react";
import { BrowserRouter, useNavigate } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
const Auth0ProviderWithRedirectCallback = ({ children, ...props }: PropsWithChildren<Auth0ProviderOptions>) => {
	const navigate = useNavigate();

	const onRedirectCallback = (appState?: AppState) => {
		navigate((appState && appState.returnTo) || window.location.pathname);
	};

	return (
		<Auth0Provider
			onRedirectCallback={onRedirectCallback}
			{...props}
		>
			{children}
		</Auth0Provider>
	);
};
root.render(
	<BrowserRouter>
		<Auth0ProviderWithRedirectCallback
			domain={process.env.REACT_APP_AUTH0_DOMAIN!}
			clientId={process.env.REACT_APP_AUTH0_CLIENTID!}
			authorizationParams={{
				audience: process.env.REACT_APP_AUTH0_AUDIENCE,
				scope: process.env.REACT_APP_AUTH0_SCOPE,
				redirect_uri: window.location.origin,
			}}
			cacheLocation="localstorage"
		>
			<App />
		</Auth0ProviderWithRedirectCallback>
	</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

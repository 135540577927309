/* eslint-disable react/jsx-no-undef */
import { useAuth0 } from "@auth0/auth0-react";
import * as React from "react";
import { useEffect, useState } from "react";
import { Accordion, Button, Col, Container, Form, ListGroup, Row } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { createPrintBatch, downloadPrintBatchAsZIP, getPrintBatchDownload, getPrintBatches, getPrintJobs } from "../api/orderportal_apimanager";
import { PrintBatch } from "../api/types/PrintBatch";
import { PrintBatchDownloadType } from "../api/types/PrintBatchDownloadType";
import { PrintJob } from "../api/types/PrintJob";
import BackButton from "../modules/backbutton";
import Navigation from "../modules/navigation";
import NotSignedIn from "../modules/notsignedin";
import toastManager from "../modules/toastmanager";

export default function PrintBatches() {
	const { isAuthenticated } = useAuth0();
	const navigate = useNavigate();
	const token = JSON.parse(localStorage.getItem("accessToken") ?? "{}");
	useEffect(() => {
		if (!token.length) navigate("/home");
	}, [navigate, token.length]);

	const [receivedPrintJobs, setReceivedPrintJobs] = useState<boolean>(false);
	const [printJobs, setPrintJobs] = useState<PrintJob[]>([]);

	const [receivedPrintBatches, setReceivedPrintBatches] = useState<boolean>(false);
	const [printBatches, setPrintBatches] = useState<PrintBatch[]>([]);

	const [currentPrintBatch, setCurrentPrintBatch] = useState<string[]>([]);
	const [amountInPrintBatch, setAmountInPrintBatch] = useState<number>(currentPrintBatch?.length);

	function togglePrintBatch(id: string) {
		var temp = currentPrintBatch;
		const index = temp.indexOf(id);
		if (index !== -1) {
			temp.splice(index, 1);
			setAmountInPrintBatch(amountInPrintBatch - 1);
			setCurrentPrintBatch(temp);
		} else {
			temp.push(id);
			setAmountInPrintBatch(amountInPrintBatch + 1);
			setCurrentPrintBatch(temp);
			if (amountInPrintBatch + 1 === 4) {
				toastManager("success", "Please confirm print batch", "The maximum amount of print jobs reached. Please confirm this batch to continue");
			}
		}
	}

	function confirmPrintBatch(event: any) {
		if (event.target.form.batch_name.value) {
			createPrintBatch(event.target.form.batch_name.value, currentPrintBatch).then((response) => {
				if (response) {
					setReceivedPrintJobs(false);
					setReceivedPrintBatches(false);
					toastManager("success", "Print batch created", `Print batch '${event.target.form.batch_name.value}' successfully created`);
					setCurrentPrintBatch([]);
					setAmountInPrintBatch(0);
				}
			});
		} else {
			toastManager("warning", `No batch name`, `A batch name is required confirm a print batch `);
		}
	}

	function downloadPrintbatch(printbatch: any) {
		getPrintBatchDownload(token, printbatch.id).then((response: PrintBatchDownloadType) => {
			downloadPrintBatchAsZIP(response);
		});
	}

	function PopulatePrintBatches() {
		var temp_array: JSX.Element[] = [];
		printBatches.forEach((printbatch) => {
			var date = new Date(0);
			date.setUTCMilliseconds(printbatch.created);
			var ref_array: JSX.Element[] = [];
			printbatch.printjob_references.forEach((reference) => {
				var print = printJobs.find((e) => e.id === reference)
				ref_array.push(
					<Row key={reference}>
						<Col>Production order:</Col>
						<Col>{print?.production_order}</Col>
					</Row>
				);
			});
			temp_array.push(
				<Accordion.Item
					key={printbatch.id}
					eventKey={printbatch.id}
				>
					<Accordion.Header>
						{printbatch.batch_name} - {date.toLocaleDateString()}
					</Accordion.Header>
					<Accordion.Body>
						{ref_array}
						<br />
						<Row>
							<Button onClick={() => downloadPrintbatch(printbatch)}>
								<Icon.Download />
								Download this print batch
							</Button>
						</Row>
					</Accordion.Body>
				</Accordion.Item>
			);
		});
		return <>{temp_array}</>;
	}

	function PopulatePrintJobList() {
		var temp_array: JSX.Element[] = [];
		printJobs.forEach((printJob) => {
			var isSelected = currentPrintBatch.some((id) => id === printJob.id);
			var date = new Date(0);
			date.setUTCMilliseconds(printJob.created);
			if (!printJob.selected_printbatch)
				temp_array.push(
					<ListGroup.Item
						key={printJob.id}
						action
						onClick={() => {
							togglePrintBatch(printJob.id);
						}}
						disabled={!(currentPrintBatch.length < 4) && !isSelected}
						variant={isSelected ? "success" : ""}
					>
						<Row>
							<Col sm={1}>{isSelected ? <Icon.PrinterFill /> : <Icon.Printer />}</Col>
							<Col>{printJob.production_order ?? "Not yet confirmed"}</Col>
							<Col>{printJob.salesorder_ref}</Col>
							<Col>
								{date.toLocaleDateString()}
							</Col>
						</Row>
					</ListGroup.Item>
				);
		});
		return <>{temp_array}</>;
	}

	useEffect(() => {
		if (!receivedPrintBatches && token && token.length) {
			getPrintBatches(token).then((response) => {
				if (response) {
					setPrintBatches(response);
					setReceivedPrintBatches(true);
				}
			});
		}
	}, [receivedPrintBatches, token]);

	useEffect(() => {
		if (!receivedPrintJobs && receivedPrintBatches && token && token.length) {
			getPrintJobs(token).then((response) => {
				if (response) {
					setPrintJobs(response);
					setReceivedPrintJobs(true);
				}
			});
		}
	}, [receivedPrintBatches, receivedPrintJobs, token]);

	return (
		<>
			<Navigation />
			{isAuthenticated && (
				<>
					<Container className="paddingTopBottom">
						<BackButton
							route="/home"
							title="homepage"
						/>
						<Row>
							<Col>
								<h1>Create print batches</h1>
								<Form>
									<Row>
										<Col>
											<Form.Control
												placeholder="Batch name"
												maxLength={50}
												required
												type="input"
												id="batch_name"
											/>
										</Col>
										<Col>
											<Button
												onClick={confirmPrintBatch}
												disabled={amountInPrintBatch === 0}
											>
												Add {amountInPrintBatch} orders to print batch
											</Button>
										</Col>
									</Row>
								</Form>
								<br />
								<ListGroup>
									<ListGroup.Item>
										<Row className="fw-bold">
											<Col sm={1}></Col>
											<Col>Pr number</Col>
											<Col>Sales reference</Col>
											<Col>Created at</Col>
										</Row>
									</ListGroup.Item>
									<PopulatePrintJobList />
								</ListGroup>
							</Col>
							<Col>
								<h1>Current print batches</h1>
								<Accordion>
									<PopulatePrintBatches />
								</Accordion>
							</Col>
						</Row>
					</Container>
				</>
			)}
			{!isAuthenticated && <NotSignedIn />}
		</>
	);
}

import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, ListGroup, Modal, Row } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { createCDNClient, uploadToHermesCache } from "../api/cdn_apimanager";
import {
	addImpressionBagCode,
	addImpressionBagCode_UltimateEars,
	addPrintJob,
	getConsumers,
	getImpressionAndRevisionID,
	getImpressions,
	getProductionOrder,
	removeProductionOrder,
	updateImpressionBagCode,
} from "../api/orderportal_apimanager";
import { Article } from "../api/types/Article";
import { CDN_response } from "../api/types/CDN_response";
import { Consumer } from "../api/types/Consumer";
import { Impression, Question } from "../api/types/Impression";
import { Order } from "../api/types/Order";
import { ProductionOrderType } from "../api/types/ProductionOrderType";
import toastManager from "./toastmanager";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import Barcode from "react-barcode";

interface Props {
	productionOrder: ProductionOrderType;
	articles: Article[];
	order: Order;
	consumers: Consumer[];
	index: number;
}

export default function ProductionOrder(props: Props) {
	const [removed, setRemoved] = useState<boolean>(false);
	const [poInfo, setPoInfo] = useState<ProductionOrderType>();
	const [infoArray, setInfoArray] = useState<JSX.Element[]>();
	const [consumers, setConsumers] = useState<Consumer[]>(props.consumers);
	const [consumer, setConsumer] = useState<Consumer>();
	const [impressions, setImpressions] = useState<Impression[]>();
	const [questions, setQuestions] = useState<string[]>();

	const chiselStatus: { [key: string]: string } = {
		created: "Created", // not really a chisel state
		SLOT_ASSIGNED: "Slot assigned",
		SLOT_MISSED: "Slot missed",
		IN_PROGRESS: "In progress",
		ON_HOLD: "On hold",
		PRINTING: "Printing",
		PRINTING_COMPLETE: "Printed",
		CANCELED: "Canceled",
		COMPLETED: "Complete",
		OBA_REQUIRED: "In progress", //? Maybe show this to the portal?
	};

	var article: Article | undefined = getArticle();
	const [extraArticles, setExtraArticles] = useState<any>();
	const [showModelInstructions, setShowModelInstructions] = useState<boolean>(false);
	const handleCloseModelInstructions = () => setShowModelInstructions(false);
	const handleOpenModelInstructions = () => setShowModelInstructions(true);

	const [showOrderDetails, setShowDetails] = useState<boolean>(false);
	const handleCloseDetails = () => setShowDetails(false);
	const handleOpenDetails = () => setShowDetails(true);

	const [showEarshellSelect, setShowEarshellSelect] = useState<boolean>(false);
	const handleCloseEarshellSelect = () => setShowEarshellSelect(false);
	const handleOpenEarshellSelect = () => setShowEarshellSelect(true);

	const [showScanner, setShowScanner] = useState<boolean>(false);
	const [impressionCode, setImpressionCode] = useState<string>();
	let chisel = process.env.REACT_APP_CHISEL_LINK;

	const token = JSON.parse(localStorage.getItem("accessToken")!);
	const navigation = useNavigate();

	function getArticle() {
		var returnarticle = undefined;
		props.articles.forEach((article: Article) => {
			if (props.productionOrder.exact_article_id === article.item_code) returnarticle = article;
		});
		return returnarticle;
	}

	function getExtraArticle(articleID: string): Article | undefined {
		return props.articles.find((article: Article) => {
			return articleID.toLowerCase() === article.item_code.toLowerCase();
		});
	}

	function removeFromOrder(event: any) {
		event.preventDefault();
		removeProductionOrder(props.order.id, props.productionOrder.id).then((result) => {
			if (result && consumer && article !== undefined) {
				toastManager("warning", `Removed from current order`, `Removed ${article.description} of ${consumer.given_name} ${consumer.family_name} from this order`);
				setRemoved(true);
			} else {
				console.error(result);
			}
		});
	}

	function AlreadyAddedBagCodeAndNoScanToday(): boolean | undefined {
		if (impressions && impressions[impressions.length - 1].id) {
			if (impressions[impressions.length - 1].left_scan_data !== "" || impressions[impressions.length - 1].right_scan_data !== "") {
				// A scan was added to this impression
				return false;
			}
			var idDate = new Date(parseInt(impressions[impressions.length - 1].id.substring(0, 8), 16) * 1000).setHours(0, 0, 0, 0);
			var today = new Date().setHours(0, 0, 0, 0);
			return today === idDate;
		}
	}
	function handleAddBagNumbers(event: any) {
		var etf = event.target.form;
		if (
			etf.bag_code.value !== "" &&
			etf.method.value !== "" &&
			etf.Q1yes.checked !== etf.Q1no.checked &&
			etf.Q2yes.checked !== etf.Q2no.checked &&
			etf.Q3yes.checked !== etf.Q3no.checked &&
			etf.Q4yes.checked !== etf.Q4no.checked &&
			etf.Q5yes.checked !== etf.Q5no.checked &&
			etf.Q6yes.checked !== etf.Q6no.checked
		) {
			if (consumer && questions) {
				var q: Question[] = [
					{ question: questions[0], answer: etf.Q1yes.checked ? "Yes" : "No" },
					{ question: questions[1], answer: etf.Q2yes.checked ? "Yes" : "No" },
					{ question: questions[2], answer: etf.Q3yes.checked ? "Yes" : "No" },
					{ question: questions[3], answer: etf.Q4yes.checked ? "Yes" : "No" },
					{ question: questions[4], answer: etf.Q5yes.checked ? "Yes" : "No" },
					{ question: questions[5], answer: etf.Q6yes.checked ? "Yes" : "No" },
				];

				if (AlreadyAddedBagCodeAndNoScanToday() && impressions) {
					updateImpressionBagCode(props.order.customer_id, consumer.id, etf.bag_code.value, etf.bag_code.value, etf.method.value, q, impressions[impressions?.length - 1].id).then(
						(response) => {
							handleCloseEarshellSelect();
							toastManager("success", "Successfully updated impression", `successfully updated the latest impression`);
						}
					);
				} else {
					addImpressionBagCode(props.order.customer_id, consumer.id, etf.bag_code.value, etf.bag_code.value, etf.method.value, q).then((response) => {
						handleCloseEarshellSelect();
						toastManager("success", "Bag serialnumber added", `successfully added bag serialnumber to ${consumer.given_name} ${consumer.family_name}`);
						addPrintJob(props.order.id, props.productionOrder.id, response.revisions[0].id).then((response) => {});
					});
				}
			}
		} else {
			toastManager("warning", "Not all the required fields are filled in", "Please fill in all the required fields to continue");
		}
	}

	function GetConsumers() {
		getConsumers(token, props.order.customer_id).then((response) => {
			setConsumers(response);
		});
	}
	//#region Ultimate Ears, can be deleted after we drop support
	const UE_SERIALS = ["100049", "100050", "100051", "100052"];
	const isUEProduct = UE_SERIALS.some((serial) => serial === article?.item_code);
	const [left, setLeft] = useState<CDN_response>();
	const [right, setRight] = useState<CDN_response>();
	const [uploaded, setUploaded] = useState<boolean>();
	createCDNClient(token);

	function handleUploadLeft(event: any) {
		upload(event.target.files[0], "left");
	}

	function handleUploadRight(event: any) {
		upload(event.target.files[0], "right");
	}

	function upload(file: any, side: string) {
		if (consumer)
			uploadToHermesCache(props.order?.tenant_id, props.order.customer_id, file).then((response) => {
				if (side === "left") setLeft(response);
				if (side === "right") setRight(response);
			});
		else {
			toastManager("error", "Something went wrong", "Please reload the page or log out and back in and try again, if problem persists please contact the administrator");
		}
	}
	function handleConfirm() {
		if (left && right) {
			const CDN_PATH = `${process.env.REACT_APP_CDN_BASEURL}/cache/hermes/${props.order.tenant_id}/${props.order.customer_id}`;
			if (consumer) {
				toastManager("info", "Processing files", "Currently updating... ");
				addImpressionBagCode_UltimateEars(props.order.customer_id, consumer?.id, `${CDN_PATH}/${left.data.document}`, `${CDN_PATH}/${right.data.document}`).then((response) => {
					if (response) {
						toastManager("success", "Done", "Files added and profile updated");
						setUploaded(true);
					} else setUploaded(false);
				});
			}
		}
	}
	//#endregion
	useEffect(
		function SetQuestions() {
			if (consumer) {
				setQuestions([
					`1: Does ${consumer.given_name} have or has had tubes in their ears?`,
					`2: Is ${consumer.given_name} currently suffering from an ear infection?`,
					`3: Does ${consumer.given_name} have or has had other problems with their ears?`,
					`4: Is ${consumer.given_name} suffering from hearing loss?`,
					`5: Does ${consumer.given_name} use a hearing aid?`,
					`6: Does ${consumer.given_name} use a smartwatch? If so, what brand?`,
				]);
			}
		},
		[consumer]
	);
	useEffect(() => {
		if (consumers)
			consumers.forEach((cons) => {
				if (cons.id === props.productionOrder.consumer_id) {
					setConsumer(cons);
				}
			});
	}, [consumers, props.productionOrder.consumer_id]);

	useEffect(() => {
		if (consumer) {
			getImpressions(token, props.order.customer_id, consumer.id).then((response) => {
				if (response && response.length !== 0) {
					setImpressions(response);
				} else setImpressions(undefined);
			});
		}
	}, [consumer, props.order.customer_id, props.order.id, props.productionOrder.id, token]);

	useEffect(() => {
		if (consumer) {
			if (props.order.locked === null && poInfo?.exact_article_id) {
				getImpressionAndRevisionID(token, props.order.customer_id, consumer.id, poInfo?.exact_article_id).then((response) => {
					if (response) addPrintJob(props.order.id, props.productionOrder.id, response.revision);
				});
			}
		}
	}, [consumer, impressions, props.order.customer_id, props.order.id, props.order.locked, props.productionOrder.id, token]);

	// Retrieve information when there is an order
	useEffect(() => {
		if (!poInfo) {
			var temp: any = [];
			getProductionOrder(token, props.order.id, props.productionOrder.id).then((response: ProductionOrderType) => {
				setPoInfo(response);
				response.extra_exact_articles.forEach((extraArticle) => {
					console.log("extraArticle", extraArticle);
					console.log(getExtraArticle(extraArticle.selected_article_exact_id));
					temp.push(`• ${getExtraArticle(extraArticle.selected_article_exact_id)?.description}`);
					temp.push(<br />);
				});
			});
			console.log(temp);
			setExtraArticles(temp);
		}
	}, [poInfo, props.order.id, props.productionOrder.id, token]);

	// Map the info in the poInfo to an array
	useEffect(() => {
		if (poInfo) {
			var productionInformationArray: JSX.Element[] = [];
			poInfo.status.forEach((orderstatus) => {
				if (orderstatus.data && orderstatus.data.status) {
					var date = new Date(0);
					date.setUTCSeconds(orderstatus.stamp / 1000);
					productionInformationArray.push(
						<Row
							key={orderstatus.stamp}
							className="center text-capitalize"
						>
							<Form.Label key={orderstatus.stamp}>
								<h2>{chiselStatus[orderstatus.data.status] ?? ""}</h2>
								<h6>
									{date.toLocaleDateString()} @ {date.toLocaleTimeString()}
								</h6>
								<hr />
							</Form.Label>
						</Row>
					);
				} else if (!orderstatus.data && orderstatus.state) {
					var date = new Date(0);
					date.setUTCSeconds(orderstatus.stamp / 1000);
					productionInformationArray.push(
						<Row
							key={orderstatus.stamp}
							className="center text-capitalize"
						>
							<Form.Label key={orderstatus.stamp}>
								<h2>{chiselStatus[orderstatus.state] ?? ""}</h2>
								<h6>
									{date.toLocaleDateString()} @ {date.toLocaleTimeString()}
								</h6>
								<hr />
							</Form.Label>
						</Row>
					);
				}
			});
			setInfoArray(productionInformationArray);
		}
	}, [poInfo]);

	if (!removed && consumer && article && questions) {
		return (
			<>
				<ListGroup.Item variant={props.index % 2 === 0 ? "light" : "none"}>
					<Row className="middle">
						<Col>
							<Button
								variant="outline-primary"
								onClick={() => navigation(`/profile?user=${consumer.id}&customer=${props.order.customer_id}&order=${props.order.id}`)}
							>
								{consumer.given_name} {consumer.family_name}
							</Button>
						</Col>
						{poInfo && (
							<>
								<Col>
									{poInfo?.chisel_pr ? (
										<a
											className="pr-number-link"
											target="_blank"
											href={`${chisel}/order/${[poInfo?.chisel_pr]}`}
											rel="noreferrer"
										>
											{poInfo?.chisel_pr}
										</a>
									) : (
										"Not assigned"
									)}
								</Col>
								<Col>
									<Button
										variant="outline-primary"
										onClick={handleOpenDetails}
									>
										{poInfo.status[poInfo.status.length - 1].data ? (
											<div className="text-truncate">{chiselStatus[poInfo.status[poInfo.status.length - 1].data.status] ?? "View status"}</div>
										) : (
											<>{poInfo.status[0].state}</>
										)}
									</Button>
								</Col>
							</>
						)}
						<Col className="d-none d-sm-block">
							{" "}
							<>{(article as Article).description}</>
						</Col>
						<Col>
							<small>{extraArticles}</small>
						</Col>
						<Col sm={1}>
							{!isUEProduct ? (
								<Button
									variant="outline-primary"
									onClick={handleOpenEarshellSelect}
								>
									{impressions === undefined ? <Icon.Ear /> : <Icon.EarFill />}
								</Button>
							) : (
								<Icon.X />
							)}
						</Col>
					</Row>
					{/* UE_ONLY_CODE */}
					<Row>
						{isUEProduct && props.order.locked == null && (
							<>
								<Col sm={4}>
									<Form.Label>Upload left earshell model</Form.Label>
									<Form.Control
										placeholder="Left"
										disabled={uploaded}
										type="file"
										id={`${props.order.id}-left`}
										accept=".stl"
										onChange={handleUploadLeft}
									/>
								</Col>
								<Col sm={1}>
									<Form.Label>Successful</Form.Label>
									{left?.success === true && <Icon.SendCheck color="green" />}
									{left?.success === false && <Icon.SendX color="red" />}
								</Col>
								<Col sm={4}>
									<Form.Label>Upload right earshell model</Form.Label>
									<Form.Control
										placeholder="Right"
										disabled={uploaded}
										type="file"
										id={`${props.order.id}-right`}
										accept=".stl"
										onChange={handleUploadRight}
									/>
								</Col>
								<Col sm={1}>
									<Form.Label>Successful</Form.Label>
									{right?.success === true && <Icon.SendCheck color="green" />}
									{right?.success === false && <Icon.SendX color="red" />}
								</Col>
								<Col sm={1}>
									<Form.Label></Form.Label>
									<Button onClick={handleConfirm}>CONFIRM</Button>
								</Col>
								<Col sm={1}>
									<Form.Label>Successful</Form.Label>
									{uploaded === true && <Icon.SendCheck color="green" />}
									{uploaded === false && <Icon.SendX color="red" />}
								</Col>
							</>
						)}
					</Row>
				</ListGroup.Item>
				<Modal
					show={showOrderDetails}
					onHide={handleCloseDetails}
				>
					<Modal.Header
						key={`${consumer.id}modalH`}
						closeButton
					>
						{" "}
						<b>
							{consumer.given_name} {consumer.family_name}{" "}
						</b>
					</Modal.Header>
					<Modal.Body>
						{infoArray}
						{props.order.locked === null && (
							<Row>
								<Button
									variant="danger"
									onClick={removeFromOrder}
								>
									REMOVE FROM ORDER
								</Button>
							</Row>
						)}
					</Modal.Body>
					<Modal.Footer>
						{" "}
						<b>{(article as Article).description} </b>
					</Modal.Footer>
				</Modal>
				<Modal
					show={showEarshellSelect}
					onHide={handleCloseEarshellSelect}
					size="lg"
				>
					<Modal.Header closeButton>
						{" "}
						<b>
							{consumer.given_name} {consumer.family_name}{" "}
						</b>
					</Modal.Header>
					<Modal.Body>
						<Form.Group>
							{AlreadyAddedBagCodeAndNoScanToday() && (
								<>
									<Alert variant="warning">
										A bag serialnumber has already been added to this user today. <br /> Adding a new impression will override the previous one.
									</Alert>
								</>
							)}
							<Form>
								<Row>
									<Col>
										<Form.Label>
											Serialnumber of bag
											<sup>
												<small>(1-13 characters)</small>
											</sup>
										</Form.Label>
										<Row>
											<Col>
												<Form.Control
													minLength={1}
													maxLength={13}
													defaultValue={impressions !== undefined ? impressions[impressions?.length - 1].bag_code_left : ""}
													type="text"
													id="bag_code"
													onChange={(event) => setImpressionCode(event.target.value)}
													value={impressionCode}
												/>
											</Col>
											<Col>
												{showScanner ? (
													<>
														<div
															onClick={() => setShowScanner(false)}
															className="fw-bold"
														>
															<Icon.X /> Close Scanner
														</div>
														<BarcodeScannerComponent
															width={500}
															height={500}
															onUpdate={(err, result) => {
																if (result) {
																	setImpressionCode(result.getText());
																	setShowScanner(false);
																}
															}}
														/>
													</>
												) : (
													<Icon.CameraFill onClick={() => setShowScanner(true)} />
												)}
											</Col>
											<Barcode
												height={25}
												textPosition="top"
												value={impressionCode ?? "10041-ZDPLLE"}
												fontSize={30}
											/>
										</Row>
									</Col>
									<Col>
										<Form.Label>
											Method
											<Icon.Info onClick={handleOpenModelInstructions} />
										</Form.Label>
										<Form.Control
											minLength={1}
											maxLength={20}
											defaultValue={impressions !== undefined ? impressions[impressions?.length - 1].method : "Dreve Otoform AK"}
											type="text"
											id="method"
										/>
									</Col>
								</Row>
								<br />
								<Row key={"Q1"}>
									<Col sm={8}>
										<Form.Label>{questions[0]}</Form.Label>
									</Col>
									<Col sm={1}>
										<Form.Check
											required
											inline
											type={"radio"}
											name={"Q1"}
											id={"Q1yes"}
											label={"Yes"}
										/>
									</Col>
									<Col sm={1}>
										<Form.Check
											required
											inline
											type={"radio"}
											name={"Q1"}
											id={"Q1no"}
											label={"No"}
										/>
									</Col>
								</Row>
								<Row key={"Q2"}>
									<Col sm={8}>
										<Form.Label>{questions[1]}</Form.Label>
									</Col>
									<Col sm={1}>
										<Form.Check
											required
											inline
											type={"radio"}
											name={"Q2"}
											id={"Q2yes"}
											label={"Yes"}
										/>
									</Col>
									<Col sm={1}>
										<Form.Check
											required
											inline
											type={"radio"}
											name={"Q2"}
											id={"Q2no"}
											label={"No"}
										/>
									</Col>
									<br />
								</Row>
								<Row key={"Q3"}>
									<Col sm={8}>
										<Form.Label>{questions[2]}</Form.Label>
									</Col>
									<Col sm={1}>
										<Form.Check
											required
											inline
											type={"radio"}
											name={"Q3"}
											id={"Q3yes"}
											label={"Yes"}
										/>
									</Col>
									<Col sm={1}>
										<Form.Check
											required
											inline
											type={"radio"}
											name={"Q3"}
											id={"Q3no"}
											label={"No"}
										/>
									</Col>
									<br />
								</Row>
								<Row key={"Q4"}>
									<Col sm={8}>
										<Form.Label>{questions[3]}</Form.Label>
									</Col>
									<Col sm={1}>
										<Form.Check
											required
											inline
											type={"radio"}
											name={"Q4"}
											id={"Q4yes"}
											label={"Yes"}
										/>
									</Col>
									<Col sm={1}>
										<Form.Check
											required
											inline
											type={"radio"}
											name={"Q4"}
											id={"Q4no"}
											label={"No"}
										/>
									</Col>
									<br />
								</Row>
								<Row key={"Q5"}>
									<Col sm={8}>
										<Form.Label>{questions[4]}</Form.Label>
									</Col>
									<Col sm={1}>
										<Form.Check
											required
											inline
											type={"radio"}
											name={"Q5"}
											id={"Q5yes"}
											label={"Yes"}
										/>
									</Col>
									<Col sm={1}>
										<Form.Check
											required
											inline
											type={"radio"}
											name={"Q5"}
											id={"Q5no"}
											label={"No"}
										/>
									</Col>
									<br />
								</Row>
								<Row key={"Q6"}>
									<Col sm={8}>
										<Form.Label>{questions[5]}</Form.Label>
									</Col>
									<Col sm={1}>
										<Form.Check
											required
											inline
											type={"radio"}
											name={"Q6"}
											id={"Q6yes"}
											label={"Yes"}
										/>
									</Col>
									<Col sm={1}>
										<Form.Check
											required
											inline
											type={"radio"}
											name={"Q6"}
											id={"Q6no"}
											label={"No"}
										/>
									</Col>
									<br />
								</Row>
								<Form.Label>Remarks:</Form.Label>
								<Form.Control
									as="textarea"
									rows={3}
								></Form.Control>
								<br />
								<small>All fields (except for remarks) are required </small>
								<Button
									className="right"
									onClick={handleAddBagNumbers}
								>
									{AlreadyAddedBagCodeAndNoScanToday() ? "Update" : "Add new"} impression
								</Button>
							</Form>
						</Form.Group>
					</Modal.Body>
					<Modal.Footer className=" ">
						{" "}
						<b>{(article as Article).description} </b>
					</Modal.Footer>
				</Modal>
				<Modal
					size="xl"
					show={showModelInstructions}
					onHide={handleCloseModelInstructions}
				>
					<Modal.Header closeButton>
						<h2>Impression instructions</h2>
					</Modal.Header>
					<Modal.Body>
						<object
							className="pdf-modal"
							data="/Voorwaarden_voor_Dopple_Protect_input_model.pdf"
							type="application/pdf"
							width="100%"
							height="100%"
						>
							<p>Open this page on desktop to view the PDF!</p>
						</object>
					</Modal.Body>
				</Modal>
			</>
		);
	} else if (consumer && article) {
		return (
			<ListGroup.Item>
				<Row>
					<Col>
						<del>
							{consumer.given_name} {consumer.family_name}
						</del>
					</Col>
					<Col>
						<del>{(article as Article).description}</del>
					</Col>
					<Col>
						<del>Removed</del>
					</Col>
				</Row>
			</ListGroup.Item>
		);
	} else {
		if (!props.consumers) GetConsumers();
		return <></>;
	}
}

import { useAuth0 } from "@auth0/auth0-react";
import * as React from "react";
import { Button, Container } from "react-bootstrap";

export default function NotSignedIn(auth?: any) {
	const { loginWithRedirect } = useAuth0();

	const login = async () => {
		await loginWithRedirect({
			authorizationParams: { prompt: "select_account"},
		});
	};
	return (
		<Container className="paddingTopBottom">
			
				<Button className="notsignedin" size="lg" variant="secondary" onClick={() => login()}>Log back in</Button>
		</Container>
	);
}

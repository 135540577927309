import { useEffect, useState } from "react";
import { Tenant } from "../api/types/Tenant";
import { getTenant } from "../api/orderportal_apimanager";

export function useTenant(tenantID?: string) {
	const [tenant, setTenant] = useState<Tenant>();
	const tenant_id = tenantID ?? localStorage.getItem("tenant");
	const token = JSON.parse(localStorage.getItem("accessToken") ?? "{}");

	useEffect(
		function RetrieveTenant() {
			if (token &&tenant_id)
				getTenant(token, tenant_id).then((response) => {
					setTenant(response as Tenant);
				});
		},
		[tenant_id, token]
	);
	return tenant;
}


export function useDepartment(){
	

}
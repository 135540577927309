import * as React from "react";
import { useState } from "react";
import { Button, Col, Form, ListGroup, Modal, Row } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { generateTag, uploadToHermesCache } from "../api/cdn_apimanager";
import { confirmModels } from "../api/orderportal_apimanager";
import { CDN_response } from "../api/types/CDN_response";
import { Customer } from "../api/types/Customer";
import { ToBeModeledType } from "../api/types/ToBeModeledType";
import toastManager from "./toastmanager";
import { error } from "console";

interface Props {
	scan: ToBeModeledType;
	tenant_id: string;
	customers: Customer[];
	// priority: React.JSX.Element
	token: string;
}

export default function ToBeModeled(props: Props) {
	const [left, setLeft] = useState<CDN_response>();
	const [right, setRight] = useState<CDN_response>();
	const [uploaded, setUploaded] = useState<boolean>();
	const [differentName, setDifferentName] = useState<boolean>(false);
	const [showDifferentNameWarning, setShowDifferentNameWarning] = useState<boolean>(false);

	function handleUploadLeft(event: any) {
		upload(event.target.files[0], "left");
	}
	function handleUploadRight(event: any) {
		upload(event.target.files[0], "right");
	}

	function upload(file: any, side: string) {
		uploadToHermesCache(props.tenant_id, props.scan.customer_id, file).then((response) => {
			if (side === "left") {
				setLeft(response);
				if (!props.scan.left_serial.includes(file.name.slice(0, -4))) {
					setDifferentName(true);
				}
			}
			if (side === "right") {
				setRight(response);
				if (!props.scan.right_serial.includes(file.name.slice(0, -4))) {
					setDifferentName(true);
				}
			}
		});
	}

	function handleConfirm(event: any) {
		if (left && right) {
			setShowDifferentNameWarning(false);
			const CDN_PATH = `${process.env.REACT_APP_CDN_BASEURL}/cache/hermes/${props.tenant_id}/${props.scan.customer_id}`;
			confirmModels(props.tenant_id, props.scan.customer_id, props.scan.id, `${CDN_PATH}/${left.data.document}`, `${CDN_PATH}/${right.data.document}`).then((response) => {
				if (response) setUploaded(true);
				else setUploaded(false);
			});
		}
	}

	function downloadFile(filepath: string) {
		var url = `${filepath}/download/?tag=${generateTag()}`;
		// eslint-disable-next-line no-restricted-globals
		location.href = url;
	}

	return (
		<Form>
			<ListGroup.Item>
				<Row>
					<Col sm={3}>
						<Icon.BuildingFill /> {props.customers.find((e) => e.id === props.scan.customer_id)?.name}
						<br />
						<Icon.TagFill />
						<> {props.scan.product_type ?? "Unknown"}</>
					</Col>
					<Col sm={2}>
						{" "}
						{left?.success === true && <h6 style={{ color: "green" }}>Successful upload</h6>}
						{left?.success === false && <h6 style={{ color: "red" }}>Upload failed</h6>}
					</Col>
					<Col sm={3}></Col>
					<Col sm={2}>
						{" "}
						{right?.success === true && <h6 style={{ color: "green" }}>Successful upload</h6>}
						{right?.success === false && <h6 style={{ color: "red" }}>Upload failed</h6>}
					</Col>
					<Col sm={2}></Col>
				</Row>
				<br />
				<Row>
					<Col sm={2}>
						<Button
							title="download"
							role="button"
							className="btn btn-primary"
							onClick={() => downloadFile(props.scan.left_scan)}
						>
							<small>
								<Icon.Download />
								{props.scan.left_serial.slice(0, -1)}.stl
							</small>
						</Button>
					</Col>
					<Col sm={3}>
						<Form.Control
							disabled={uploaded}
							type="file"
							id={`${props.scan.id}-left`}
							accept=".stl"
							onChange={handleUploadLeft}
						/>
					</Col>
					<Col sm={2}>
						<Button
							title="download"
							role="button"
							className="btn btn-primary"
							onClick={() => downloadFile(props.scan.right_scan)}
						>
							<small>
								<Icon.Download />
								{props.scan.right_serial.slice(0, -1)}.stl
							</small>
						</Button>
					</Col>
					<Col sm={3}>
						<Form.Control
							disabled={uploaded}
							type="file"
							id={`${props.scan.id}-right`}
							accept=".stl"
							onChange={handleUploadRight}
						/>
					</Col>

					<Col sm={2}>
						{left && right && !uploaded && (
							<>
								{differentName ? (
									<Button
										variant="warning"
										onClick={() => setShowDifferentNameWarning(true)}
									>
										Confirm
									</Button>
								) : (
									<Button onClick={handleConfirm}>Confirm</Button>
								)}
							</>
						)}
						{uploaded === true && <h6 style={{ color: "green" }}>Successfully confirmed</h6>}
						{uploaded === false && <h6 style={{ color: "red" }}>Confirming failed</h6>}
					</Col>
				</Row>
				<Modal show={showDifferentNameWarning}>
					<Modal.Header className="middle">
						<Modal.Title >
							<Icon.ExclamationTriangleFill color="#DDB100" />
							Warning
							<Icon.ExclamationTriangleFill color="#DDB100" />
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						The name of the scan(s) does not correspond to the modeled filename. <br /> <br /> If this was not intentional you can cancel the confirmation and reupload the correct
						file{" "}
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="danger"
							onClick={() => setShowDifferentNameWarning(false)}
						>
							Cancel confirmation
						</Button>
						<Button
							variant="success"
							onClick={handleConfirm}
						>
							Accept and upload
						</Button>
					</Modal.Footer>
				</Modal>
			</ListGroup.Item>
		</Form>
	);
}
